import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VSkeletonLoader,
  VSpacer,
  VTimeline,
  VTimelineItem,
} from "vuetify/lib";
import { mapActions } from "vuex";

import AudioPlayer from "@/components/AudioPlayer";
import { DIAL_LOG_STATUS_TYPE } from "@/constants/valueIcons";
import { formatTimeWithTimezone } from "@/helpers/dateAndTimeUtils";
import { Actions, Modules } from "@/models/store";

const DialLogTimeline = Vue.extend({
  props: {
    data: Array,
    startDate: String,
    hasRecord: Boolean,
    withFullConverstaion: {
      type: Boolean,
      default: false,
    },
    guid: String,
  },

  data: () => ({
    showAsChat: false,
    playerTime: 0,
    audioTimeShift: 0,
    record: null as any,
    isRecordLoading: false,
    conversationEnabled: false,
  }),

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.AUDIO_RECORD_FETCH]),

    getEventTime(timestamp: string): string {
      const timeDifference =
        new Date(timestamp).getTime() - new Date(this.startDate).getTime();
      const timeInSeconds = Math.round(timeDifference / 1000);

      return this.formatTime(timeInSeconds);
    },

    calcAudioTime(timeStamp: string, type: number) {
      return Number(
        (
          Math.round(
            new Date(timeStamp).getTime() - new Date(this.startDate).getTime()
          ) /
            1000 -
          this.audioTimeShift -
          (type === 5 ? 1.2 : 0)
        ).toFixed(3)
      );
    },

    formatTime(seconds: number): string {
      let formattedSeconds = 0;
      let formattedMinutes = 0;

      if (seconds < 60) {
        formattedSeconds = seconds;
      } else {
        formattedMinutes = Math.trunc(seconds / 60);
        formattedSeconds = seconds % 60;
      }

      return `${
        formattedMinutes > 9 ? formattedMinutes : `0${formattedMinutes}`
      }:${formattedSeconds > 9 ? formattedSeconds : `0${formattedSeconds}`}`;
    },

    getStatusName(type: number, parameters: any): any {
      if (
        (type === 3 && parameters?.partyId) ||
        (type === 5 && parameters?.partyId)
      ) {
        return parameters?.partyId === "1" ? "Party 1" : "Party 2";
      }

      return DIAL_LOG_STATUS_TYPE[type]?.name;
    },

    getStatusColor(type: number, parameters: any) {
      if (
        (type === 3 && parameters?.partyId) ||
        (type === 5 && parameters?.partyId)
      ) {
        return parameters?.partyId === "1" ? "tertiary" : "accent";
      }

      return DIAL_LOG_STATUS_TYPE[type]?.color;
    },

    async handleFetchRecord() {
      if (!this.hasRecord) {
        return;
      }

      this.isRecordLoading = true;

      this.record = await this.AUDIO_RECORD_FETCH({
        guid: this.guid,
        withFullConversation: this.withFullConverstaion,
      });

      this.isRecordLoading = false;

      const startBlock: any = this.data?.find(({ type }: any) => type === 1);

      if (!startBlock) {
        return;
      }

      this.audioTimeShift =
        Math.round(
          new Date(startBlock?.timeStamp).getTime() -
            new Date(this.startDate).getTime()
        ) / 1000;

      this.conversationEnabled = this.withFullConverstaion;
    },
  },

  async created() {
    this.handleFetchRecord();
  },

  render(): VNode {
    return (
      <div>
        <div class="d-flex align-center">
          <h3 class="text-h6 primary--text">Conversation flow</h3>
          <VSpacer />
          <VBtn
            onClick={() => (this.showAsChat = !this.showAsChat)}
            class="secondary primary--text"
            rounded
          >
            <VIcon left>
              {this.showAsChat
                ? "mdi-format-list-bulleted"
                : "mdi-forum-outline"}
            </VIcon>
            Display as {this.showAsChat ? "list" : "chat"}
          </VBtn>
        </div>
        <div key={`${this.guid}-${this.conversationEnabled}`}>
          {this.record && (
            <AudioPlayer
              class="mt-1"
              flat
              file={this.record?.audio}
              color="tertiary"
              ref="player"
              on={{
                "update:playerTime": (i: number) => (this.playerTime = i),
              }}
            />
          )}
          {this.isRecordLoading && (
            <VSkeletonLoader type="paragraph" class="my-7" />
          )}
          <VTimeline alignTop dense={!this.showAsChat} class="px-1">
            {this.data?.map(
              (
                { timeStamp, type, activity, parameters }: any,
                index: number
              ) => {
                const audioTime = this.calcAudioTime(timeStamp, type);
                const endAudioTime = this.data?.[index + 1]
                  ? this.calcAudioTime(
                      (this.data as any)[index + 1].timeStamp,
                      (this.data as any)[index + 1].type
                    )
                  : 999;
                return this.showAsChat ? (
                  <VTimelineItem
                    small
                    fillDot
                    icon={DIAL_LOG_STATUS_TYPE[type]?.icon}
                    color={this.getStatusColor(type, parameters)}
                    right={type === 5 || parameters?.partyId === "2"}
                    left={
                      (type !== 5 && parameters?.partyId !== "2") ||
                      (type === 5 && parameters?.partyId === "1")
                    }
                  >
                    <VCard
                      class={[
                        {
                          "elevation-8":
                            this.playerTime >= audioTime &&
                            this.playerTime < endAudioTime &&
                            !this.withFullConverstaion,
                        },
                      ]}
                    >
                      <VCardText class="pa-3">
                        <div
                          class="font-weight-normal cursor-pointer"
                          onClick={() =>
                            (this.$refs.player as any)?.seek(audioTime, true)
                          }
                        >
                          <strong
                            class={`${this.getStatusColor(
                              type,
                              parameters
                            )}--text`}
                          >
                            {this.getStatusName(type, parameters)}
                          </strong>
                          &nbsp;|&nbsp;
                          <strong>{this.getEventTime(timeStamp)}</strong>
                          &nbsp;-&nbsp;
                          {formatTimeWithTimezone(timeStamp)}
                        </div>
                        {activity && <div>{activity}</div>}
                      </VCardText>
                    </VCard>
                  </VTimelineItem>
                ) : (
                  <VTimelineItem
                    small
                    fillDot
                    class={[
                      "pt-2 pb-4",
                      {
                        "indigo lighten-5 rounded-lg":
                          this.playerTime >= audioTime &&
                          this.playerTime < endAudioTime &&
                          !this.withFullConverstaion,
                      },
                    ]}
                    icon={DIAL_LOG_STATUS_TYPE[type]?.icon}
                    color={this.getStatusColor(type, parameters)}
                  >
                    <div>
                      {}
                      <div
                        class="font-weight-normal cursor-pointer"
                        onClick={() =>
                          (this.$refs.player as any)?.seek(audioTime, true)
                        }
                      >
                        <strong
                          class={`${this.getStatusColor(
                            type,
                            parameters
                          )}--text`}
                        >
                          {this.getStatusName(type, parameters)}
                        </strong>
                        &nbsp;|&nbsp;
                        <strong>{this.getEventTime(timeStamp)}</strong>
                        &nbsp;-&nbsp;
                        {formatTimeWithTimezone(timeStamp)}
                      </div>
                      {activity && <div>{activity}</div>}
                    </div>
                  </VTimelineItem>
                );
              }
            )}
          </VTimeline>
        </div>
      </div>
    );
  },
});

export default DialLogTimeline;
